import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector, useDispatch, useStore, shallowEqual } from 'react-redux';
import mqtt from 'mqtt/dist/mqtt';
import * as _ from 'lodash';
import moment from 'moment';
import 'moment/locale/zh-hk';
import Routes from './routes';
import ReactGA from 'react-ga4';

import { usePrevious } from './utils/hooks';
import { LANG, MQTT_MSG_TYPE, MQTT_OPTIONS, STATUS, TICKET_DISPLAY_TYPE } from './constants/constants';
import { API_URLS } from './constants/apiUrls';
import { PATH } from './constants/paths';
import { getOne } from './utils/baseFetch';
import { setPickup, setImage, setLatestTag as setPickupLatestTag } from './reducers/pickupSlice';
import { setToken, setLang } from './reducers/systemSlice';
import {
  setTagSequenceListByMqtt,
  setTicketSequenceListByMqtt,
  setCounterTagListByMqtt,
  setLatestTag,
  updateTransferrableTicketList,
  addTransferrableTickettUpdateVersion,
} from './reducers/queueSlice';
import { setTimeSessionUpdateTimestamp } from './reducers/tvSlice';
import { setTicket } from './reducers/ticketSlice';
import { getCounterName, isAndroid, isIOS } from './utils/utils';
import { getReservationTicket } from './utils/reservationUtils';

import { Loading } from './components/Loading';
import { Dialog } from './components/Dialog';

import './styles/global.scss';
import './styles/queue.scss';
import './styles/reservation.scss';
import './styles/themes/tb-red-orange.scss';
import './styles/themes/testing-centre.scss';
import './styles/themes/mtr-taxi.scss';
import './styles/themes/counter.scss';
import './styles/themes/jockey-club.scss';
import './styles/themes/leave-home-safe.scss';
import './styles/themes/leave-home-safe-counter.scss';
import './styles/themes/hang-seng.scss';
import './styles/themes/cichk.scss';
import './styles/themes/highSpeedRail.scss';
import './styles/themes/airline.scss';

let ticketInState = undefined;
let transferrableTicketListInState = undefined;
let languageInState = undefined;

const App = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const loading = useSelector((state) => state.system.loading);
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);
  const ticket = useSelector((state) => state.ticket.ticket);
  const transferrableTicketList = useSelector((state) => state.queue.transferrableTicketList);
  // mqtt
  const mqttTopicList = useSelector((state) => state.system.mqttTopicList, shallowEqual);
  const previousMqttTopicList = usePrevious(mqttTopicList, []);
  const [mqttClient, setMqttClient] = useState(undefined);
  // const [mqttHealthCheckTimeout, setMqttHealthCheckTimeout] = useState(undefined);
  // const [lastReceiveMqttTimestamp, setLastReceiveMqttTimestamp] = useState(0);

  useEffect(() => {
    ticketInState = ticket;
  }, [ticket]);

  useEffect(() => {
    transferrableTicketListInState = transferrableTicketList;
  }, [transferrableTicketList]);

  useEffect(() => {
    languageInState = lang;
  }, [lang]);

  window.setUserProfile = (token, tempLang) => {
    if (token) {
      dispatch(setToken(token));
    }
    if (tempLang) {
      switch (tempLang.toUpperCase()) {
        case LANG.EN:
        case LANG.SC:
          dispatch(setLang(tempLang.toUpperCase()));
          break;
        default:
          dispatch(setLang(LANG.TC));
      }
    }
  };

  useEffect(() => {
    try {
      if (isAndroid()) {
        if (window.getUserProfile !== undefined) {
          window.getUserProfile.postMessage('');
        } else {
          eval('Android.getUserProfile()');
        }
      } else if (isIOS()) {
        window.webkit.messageHandlers.getUserProfile.postMessage('');
      } else {
        //   web
      }
    } catch (e) {
      console.log('cannot get user profile');
      // this.hideLoading();
    }

    resetMqttClient();
    // resetMqttTimeout();
    ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_ID}`);

    return function unsubscribeMqtt() {
      // if (mqttHealthCheckTimeout) {
      //   clearTimeout(mqttHealthCheckTimeout);
      // }
      if (mqttClient) {
        mqttClient.end();
      }
    };
  }, []);

  useEffect(() => {
    moment.locale(lang === LANG.EN ? 'en' : 'zh-hk');
  }, [lang]);

  // useEffect(() => {
  //   console.log('subscribe everything', mqttTopicList);
  //   if (mqttTopicList.length > 0) {
  //     mqttClient.subscribe(mqttTopicList, function (err) {
  //       if (err) {
  //         console.log(err);
  //       }
  //     });
  //   }
  // }, [mqttClient && mqttClient.connected])

  useEffect(() => {
    // console.log('no mqttClient');
    const newTopics = _.difference(mqttTopicList, previousMqttTopicList);
    const oldTopics = _.difference(previousMqttTopicList, mqttTopicList);

    // console.log('newTopics', newTopics);
    // console.log('oldTopics', oldTopics);

    if (newTopics.length > 0) {
      // console.log('subscribe newTopics', newTopics);
      mqttClient.subscribe(newTopics, function (err) {
        if (err) {
          console.log(err);
        }
      });
    }
    if (oldTopics.length > 0) {
      // console.log('unsubscribe oldTopics', oldTopics);
      mqttClient.unsubscribe(oldTopics, function (err) {
        if (err) {
          console.log(err);
        }
      });
    }
  }, [mqttClient && mqttTopicList]);

  const resetMqttClient = () => {
    // console.log('resetMqttClient');
    if (mqttClient) {
      mqttClient.end();
    }

    const client = mqtt.connect(`${process.env.REACT_APP_MQTT_HOST}`, {
      ...MQTT_OPTIONS,
      clientId: MQTT_OPTIONS.clientId + Math.random().toString(16).substring(2, 8),
    });

    client.on('connect', function () {
      // console.log('connect');
      if (mqttTopicList.length > 0) {
        // console.log('subscribe every topics', mqttTopicList);
        client.subscribe(mqttTopicList, function (err) {
          if (err) {
            console.log(err);
          }
        });
      }
    });

    client.on('reconnect', function () {
      // console.log('reconnect');
      const currentPath = window.location.pathname;
      if (currentPath.indexOf(PATH.PICKUP) !== -1) {
        const siteId = currentPath.substring(currentPath.lastIndexOf('/') + 1);
        getOne(
          API_URLS.PICKUP + siteId,
          undefined,
          (payload) => {
            dispatch(setPickup(payload.pickupTagList));
            dispatch(setImage(payload.image));
          },
          undefined,
          store
        );
      }
    });

    client.on('message', function (topic, message) {
      // console.log('topic', topic);
      // console.log('mqtt message', message.toString());
      const topicContents = _.split(topic, '/');
      try {
        const messageJson = JSON.parse(message);
        switch (messageJson.action) {
          case MQTT_MSG_TYPE.TOPIC_ALIVE:
            break;
          // pickup
          case MQTT_MSG_TYPE.PICKUP_ADD:
          case MQTT_MSG_TYPE.PICKUP_REMOVE:
          case MQTT_MSG_TYPE.PICKUP_REMOVE_ALL:
            if (topicContents.length > 1) {
              // {"action":"ADD_PICKUP_TAG","tagLabel":"2","timestamp":1685695012106}
              // {"action":"REMOVE_PICKUP_TAG","tagLabel":"1","timestamp":1685694913059}
              // {"action":"REMOVE_ALL_PICKUP_TAG","timestamp":1685695027366}
              getOne(
                API_URLS.PICKUP + topicContents[1],
                undefined,
                (payload) => {
                  dispatch(setPickup(payload.pickupTagList));
                  dispatch(setImage(payload.image));
                  if (messageJson.tagLabel !== undefined) {
                    dispatch(setPickupLatestTag(messageJson.tagLabel));
                  }
                },
                undefined,
                store
              );
            }
            break;
          // queue
          case MQTT_MSG_TYPE.UPDATE_TIME_SESSION:
            const currentPath = window.location.pathname;
            if (currentPath.substring(3).startsWith(PATH.TV)) {
              dispatch(setTimeSessionUpdateTimestamp(messageJson.timestamp))
            }
            break;
          case MQTT_MSG_TYPE.TO_QUEUE_TICKET:
            // {"action”:"TO_QUEUE_TICKET”,"queueTicketId":"8861297d-3d4d-4f10-bd23-abd2ea0a1d74","timestamp":1684463468696}
            const newParams = {
              ticketId: topicContents[topicContents.length - 1],
            };
            getReservationTicket(newParams, dispatch, store);
            break;
          case MQTT_MSG_TYPE.SUBSTITUTE:
            if (ticketInState && ticketInState.displayType === TICKET_DISPLAY_TYPE.TRANSFERABLE && messageJson.substitute === true) {
              for (const ticket of transferrableTicketListInState) {
                if (ticket.id === messageJson.ticketId && (!ticket.updateTimestamp || ticket.updateTimestamp <= Number(messageJson.timestamp))) {
                  dispatch(
                    updateTransferrableTicketList({
                      updateId: messageJson.ticketId,
                      updateDetail: {
                        counterName: '',
                        updateTimestamp: Number(messageJson.timestamp),
                      },
                    })
                  );
                }
              }
              return;
            }

            if (
              ticketInState &&
              ticketInState.id === messageJson.ticketId &&
              messageJson.substitute === true &&
              (!ticketInState.updateTimestamp || ticketInState.updateTimestamp <= Number(messageJson.timestamp))
            ) {
              // {"action":"SUBSTITUTE","ticketId":"27563c50-9972-4a6e-b9ec-1dc1d33b44dc","substitute":true,"timestamp":1676968423067}
              const tempTicket = _.cloneDeep(ticketInState);
              tempTicket.substitute = true;
              tempTicket.counterName = '';
              tempTicket.updateTimestamp = Number(messageJson.timestamp);
              dispatch(setTicket(tempTicket));
            }
            break;
          case MQTT_MSG_TYPE.DELETE_TICKET:
            if (ticketInState && ticketInState.displayType === TICKET_DISPLAY_TYPE.TRANSFERABLE) {
              for (const ticket of transferrableTicketListInState) {
                if (ticket.id === messageJson.ticketId && (!ticket.updateTimestamp || ticket.updateTimestamp <= Number(messageJson.timestamp))) {
                  dispatch(
                    updateTransferrableTicketList({
                      updateId: messageJson.ticketId,
                      updateDetail: {
                        status: STATUS.DEACTIVE,
                        deleteTimestamp: Number(messageJson.timestamp),
                        updateTimestamp: Number(messageJson.timestamp),
                      },
                    })
                  );
                }
              }
            }

            if (
              ticketInState &&
              ticketInState.id === messageJson.ticketId &&
              (!ticketInState.updateTimestamp || ticketInState.updateTimestamp <= Number(messageJson.timestamp))
            ) {
              const tempTicket = _.cloneDeep(ticketInState);
              tempTicket.status = STATUS.DEACTIVE;
              tempTicket.updateTimestamp = Number(messageJson.timestamp);
              dispatch(setTicket(tempTicket));
            }
            break;
          case MQTT_MSG_TYPE.REJECT_TICKET:
            if (ticketInState && ticketInState.displayType === TICKET_DISPLAY_TYPE.TRANSFERABLE) {
              for (const ticket of transferrableTicketListInState) {
                if (ticket.id === messageJson.ticketId && (!ticket.updateTimestamp || ticket.updateTimestamp <= Number(messageJson.timestamp))) {
                  dispatch(
                    updateTransferrableTicketList({
                      updateId: messageJson.ticketId,
                      updateDetail: {
                        status: STATUS.REJECT,
                        deleteTimestamp: Number(messageJson.timestamp),
                        updateTimestamp: Number(messageJson.timestamp),
                      },
                    })
                  );
                }
              }
            }

            if (
              ticketInState &&
              ticketInState.id === messageJson.ticketId &&
              (!ticketInState.updateTimestamp || ticketInState.updateTimestamp <= Number(messageJson.timestamp))
            ) {
              const tempTicket = _.cloneDeep(ticketInState);
              tempTicket.status = STATUS.REJECT;
              tempTicket.updateTimestamp = Number(messageJson.timestamp);
              dispatch(setTicket(tempTicket));
            }
            break;
          case MQTT_MSG_TYPE.CHECK_IN:
            if (ticketInState && ticketInState.displayType === TICKET_DISPLAY_TYPE.TRANSFERABLE) {
              for (const ticket of transferrableTicketListInState) {
                if (ticket.id === messageJson.ticketId && (!ticket.updateTimestamp || ticket.updateTimestamp <= Number(messageJson.timestamp))) {
                  dispatch(
                    updateTransferrableTicketList({
                      updateId: messageJson.ticketId,
                      updateDetail: {
                        status: STATUS.ACTIVE,
                        counterName: getCounterName(languageInState, messageJson),
                        checkInTimestamp: Number(messageJson.timestamp),
                        updateTimestamp: Number(messageJson.timestamp),
                      },
                    })
                  );
                }
              }
              return;
            }

            if (
              ticketInState &&
              ticketInState.id === messageJson.ticketId &&
              (!ticketInState.updateTimestamp || ticketInState.updateTimestamp <= Number(messageJson.timestamp))
            ) {
              // {"action":"CHECK_IN","ticketId":"dbd476e1-e25b-4665-be50-51cfe4b12e82","counterName":"19","timestamp":1676367002364}
              const tempTicket = _.cloneDeep(ticketInState);
              tempTicket.status = STATUS.ACTIVE;
              tempTicket.counterName = getCounterName(languageInState, messageJson);
              tempTicket.updateTimestamp = Number(messageJson.timestamp);
              dispatch(setTicket(tempTicket));
            }
            break;
          case MQTT_MSG_TYPE.TRANSFER:
            if (ticketInState && ticketInState.id === messageJson.transferableTicketId) {
              dispatch(addTransferrableTickettUpdateVersion());
            }

            break;
          case MQTT_MSG_TYPE.CHECK_OUT:
            if (ticketInState && ticketInState.displayType === TICKET_DISPLAY_TYPE.TRANSFERABLE) {
              for (const ticket of transferrableTicketListInState) {
                if (ticket.id === messageJson.ticketId && (!ticket.updateTimestamp || ticket.updateTimestamp <= Number(messageJson.timestamp))) {
                  dispatch(
                    updateTransferrableTicketList({
                      updateId: messageJson.ticketId,
                      updateDetail: {
                        status: STATUS.ACTIVE,
                        checkOutTimestamp: Number(messageJson.timestamp),
                        updateTimestamp: Number(messageJson.timestamp),
                      },
                    })
                  );
                }
              }
              return;
            }

            if (
              ticketInState &&
              ticketInState.id === messageJson.ticketId &&
              (!ticketInState.updateTimestamp || ticketInState.updateTimestamp <= Number(messageJson.timestamp))
            ) {
              // {"action":"CHECK_OUT","ticketId":"796f499c-5404-4b99-bcad-a0c75c63371c","timestamp":1684462708810}
              const tempTicket = _.cloneDeep(ticketInState);
              tempTicket.status = STATUS.ACTIVE;
              // tempTicket.counterName = getCounterName(languageInState, messageJson);
              tempTicket.checkOutTimestamp = Number(messageJson.timestamp);
              tempTicket.updateTimestamp = Number(messageJson.timestamp);
              dispatch(setTicket(tempTicket));
            }
            break;
          case MQTT_MSG_TYPE.UPDATE_PICKUP_TICKET:
            if (
              ticketInState &&
              ticketInState.id === messageJson.ticketId &&
              (!ticketInState.updateTimestamp || ticketInState.updateTimestamp <= Number(messageJson.timestamp))
            ) {
              // {"action":"CHECK_OUT","ticketId":"796f499c-5404-4b99-bcad-a0c75c63371c","timestamp":1684462708810}
              const tempTicket = _.cloneDeep(ticketInState);
              // tempTicket.status = STATUS.ACTIVE;
              tempTicket.pickupTypeName = getCounterName(languageInState, messageJson);
              tempTicket.updateTimestamp = Number(messageJson.timestamp);
              dispatch(setTicket(tempTicket));
            }
            break;
          case MQTT_MSG_TYPE.TAG:
            // {"counterSoundType":"WINDOW”,"fromTicketLabel":"A001","toTicketLabel":"A005","toHiddenSequence":1000500,
            // "action":"ROLL_TAG",”from”:1,"to":5,"ticketId":"30d5af38-95e4-4e94-9fb9-081824b085d0","counterName":"4",
            // "timestamp":1684400252221}

            if (ticketInState && ticketInState.displayType === TICKET_DISPLAY_TYPE.TRANSFERABLE) {
              for (const ticket of transferrableTicketListInState) {
                const tagStart = messageJson.from !== undefined ? Number(messageJson.from) : Number(messageJson.to);
                const tagEnd = Number(messageJson.to);
                const mqttTimestamp = Number(messageJson.timestamp);
                const timesectionId = topicContents[4];
                const tableType = topicContents[5];
                if (
                  ticket.timeSessionId === timesectionId &&
                  ticket.tableType === tableType &&
                  ticket.ticketNumber >= tagStart &&
                  ticket.ticketNumber <= tagEnd &&
                  (!ticket.tagUpdateTimestamp || ticket.tagUpdateTimestamp <= mqttTimestamp)
                ) {
                  dispatch(
                    updateTransferrableTicketList({
                      updateId: ticket.id,
                      updateDetail: {
                        tagNumber: messageJson.to,
                        counterName: getCounterName(languageInState, messageJson),
                        callTimestamp: mqttTimestamp,
                        tagUpdateTimestamp: mqttTimestamp,
                      },
                    })
                  );
                } else if (
                  ticket.timeSessionId === timesectionId &&
                  ticket.tableType === tableType &&
                  (!ticket.tagUpdateTimestamp || ticket.tagUpdateTimestamp <= mqttTimestamp)
                ) {
                  dispatch(
                    updateTransferrableTicketList({
                      updateId: ticket.id,
                      updateDetail: {
                        tagNumber: messageJson.to,
                        tagUpdateTimestamp: mqttTimestamp,
                      },
                    })
                  );
                }
              }

              return;
            }

            dispatch(
              setTagSequenceListByMqtt({
                topicContents: topicContents,
                messageJson: messageJson,
              })
            );
            const tagStart = messageJson.from !== undefined ? Number(messageJson.from) : Number(messageJson.to);
            const tagEnd = Number(messageJson.to);
            const mqttTimestamp = Number(messageJson.timestamp);
            if (
              ticketInState &&
              ticketInState.ticketNumber >= tagStart &&
              ticketInState.ticketNumber <= tagEnd &&
              (!ticketInState.updateTimestamp || ticketInState.updateTimestamp <= mqttTimestamp)
            ) {
              const tempTicket = _.cloneDeep(ticketInState);
              tempTicket.counterName = getCounterName(languageInState, messageJson);
              tempTicket.callTimestamp = mqttTimestamp;
              tempTicket.updateTimestamp = mqttTimestamp;
              dispatch(setTicket(tempTicket));
            }
            if (messageJson.counterName) {
              dispatch(
                setCounterTagListByMqtt({
                  topicContents: topicContents,
                  messageJson: { ...messageJson, counterName: getCounterName(languageInState, messageJson) },
                })
              );
              dispatch(setLatestTag(messageJson.toTicketLabel));
            }
            break;
          case MQTT_MSG_TYPE.TICKET:
            // {"nextTicketNum":6,"action":"UPDATE_TICKET_SEQUENCE","timestamp":1684394214592}
            dispatch(
              setTicketSequenceListByMqtt({
                topicContents: topicContents,
                messageJson: messageJson,
              })
            );
            break;
          case MQTT_MSG_TYPE.SPEAK:
            // {"counterSoundType":"WINDOW","ticketLabel":"035","action":"SPEAK","ticketId":"8861297d-3d4d-4f10-bd23-abd2ea0a1d74",
            // "substitute":false,"counterName":"4","timestamp":1684468234874}
            if (ticketInState && ticketInState.displayType === TICKET_DISPLAY_TYPE.TRANSFERABLE) {
              for (const ticket of transferrableTicketListInState) {
                if (ticket.id === messageJson.ticketId && (!ticket.updateTimestamp || ticket.updateTimestamp <= Number(messageJson.timestamp))) {
                  dispatch(
                    updateTransferrableTicketList({
                      updateId: messageJson.ticketId,
                      updateDetail: {
                        counterName: getCounterName(languageInState, messageJson),
                        updateTimestamp: Number(messageJson.timestamp),
                      },
                    })
                  );
                }
              }

              return;
            }

            if (messageJson.counterName) {
              dispatch(
                setCounterTagListByMqtt({
                  topicContents: topicContents,
                  messageJson: { ...messageJson, toTicketLabel: ticketInState.ticketLabel },
                })
              );
              dispatch(setLatestTag(ticketInState.ticketLabel));
            }
            if (
              ticketInState &&
              ticketInState.id === messageJson.ticketId &&
              (ticketInState.updateTimestamp || ticketInState.updateTimestamp <= Number(messageJson.timestamp))
            ) {
              const tempTicket = _.cloneDeep(ticketInState);
              tempTicket.substitute = false;
              tempTicket.counterName = getCounterName(languageInState, messageJson);
              tempTicket.updateTimestamp = Number(messageJson.timestamp);
              dispatch(setTicket(tempTicket));
            }
            break;
          default:
        }
      } catch (e) {
        console.log('Cannot parse mqtt message', e);
      }
      // setLastReceiveMqttTimestamp(Date.now());
    });

    client.on('error', function (error) {
      console.log(error);
    });
    setMqttClient(client);
  };

  // useEffect(() => {
  //   console.log('mqttHealthCheckTimeout', mqttHealthCheckTimeout);
  //   if (mqttHealthCheckTimeout) {
  //     console.log('clear timeoutId', mqttHealthCheckTimeout);
  //     clearTimeout(mqttHealthCheckTimeout);
  //   }
  //   const timeout = setTimeout(() => {
  //     const currentTimestamp = Date.now();
  //     console.log('inside timeout', currentTimestamp);
  //     console.log('lastReceiveMqttTimestamp', lastReceiveMqttTimestamp);
  //     if (currentTimestamp - lastReceiveMqttTimestamp > MQTT_RESET_TIME) {
  //       console.log('RESET');
  //       // reset mqtt
  //       if (mqttClient) {
  //         mqttClient.end(true, () => { resetMqttClient(currentTimestamp); });
  //       } else {
  //         resetMqttClient(currentTimestamp);
  //       }
  //     }
  //   }, MQTT_RESET_TIME);

  //   console.log('timeoutId', timeout);
  //   setMqttHealthCheckTimeout(timeout);
  // }, [lastReceiveMqttTimestamp]);

  return (
    <div className={'main-content ' + theme}>
      <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
        <Routes />
      </BrowserRouter>
      <Loading openLoading={loading} />
      <Dialog />
    </div>
  );
};

export default App;
